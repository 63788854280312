import React from 'react';

import { withFirebase } from '../../utils/Firebase';
import cn from 'classnames';
import Button from '../Buttons/PrimaryButton';
import Input from '../Input';
import Checkbox from '../Input/Checkbox';
import Loading from '../Loading';

import style from './CalculatorSettings.scss';

const defaultSettings = {
  interestRateAnnual: 4.5, // percents
  homePrice: 350000,
  downPaymentPercent: 20, // percents
  loanTerm: 10, //years
  propertyTaxRate: 1.5, // percents
  homeInsuranceRate: 0.4, // percents
  ratesState: 'CA', // state, required
  loanLimitsCounty: 'San Diego', // required
  isTaxEnabled: true,
  creditScore: 740,
  zipCode: '92123', // required
  // loanLimits: loanLimits // required - auto populate from state and county
  defaultCalculator: 'refinance', // conventional
  showConventional: true,
  showFHA: true,
  showVA: true,
  showUSDA: false,
  showRefinance: true,
  showAffordability: false,
};

const CalculatorSettings = ({ firebase, authUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [settingsLoading, setSettingsLoading] = React.useState(true);
  const [values, setValues] = React.useState(defaultSettings);

  const getSettings = async () => {
    const docRef = await firebase.firestore.collection('calculator_settings').doc(authUser.uid);

    docRef.onSnapshot((snap) => {
      if (snap.data()) {
        console.log('Document data:', snap.data());
        setValues(snap.data());
      } else {
        console.log('No such document!');
      }
      setSettingsLoading(false);
    });
  };

  React.useEffect(() => {
    if (firebase) {
      getSettings();
    }
  }, [firebase]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setValues({
      ...values,
      [name]: value,
    });

    console.log(values);
  };

  const handleCheckboxChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { id } = e.target;

    await firebase.firestore.collection('calculator_settings').doc(id.value).set(values);

    setIsLoading(false);
  };
  return (
    <>
      <div className={cn('row', style.calculatorSettings)}>
        <div className="col-12">
          <h1>Calculator settings</h1>
        </div>
        <>
          <div className="col-12">
            {!settingsLoading ? (
              <>
                <form onSubmit={handleSubmit}>
                  <h2>Base settings</h2>
                  <div className={style.inputs}>
                    <Input
                      name="interestRateAnnual"
                      value={values?.interestRateAnnual}
                      labelName="Interest Rate"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="homePrice"
                      value={values?.homePrice}
                      labelName="Home price value"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="downPaymentPercent"
                      value={values?.downPaymentPercent}
                      labelName="Down payment"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="loanTerm"
                      value={values?.loanTerm}
                      labelName="Loan term"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="propertyTaxRate"
                      value={values?.propertyTaxRate}
                      labelName="Property tax rate"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="homeInsuranceRate"
                      value={values?.homeInsuranceRate}
                      labelName="Home Insurance rate"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="ratesState"
                      value={values?.ratesState}
                      labelName="State"
                      onChange={handleInputChange}
                    />
                    <Input
                      name="loanLimitsCounty"
                      value={values?.loanLimitsCounty}
                      labelName="County"
                      onChange={handleInputChange}
                    />
                    {/* TODO: Check if it is required */}
                    <Input
                      name="zipCode"
                      value={values?.zipCode}
                      labelName="Zip code"
                      onChange={handleInputChange}
                    />
                    {/* <Input
                  name="loanLimits"
                  value={values?.loanLimits}
                  labelName="Loan limits"
                  onChange={handleInputChange}
                  defaultValue={''}
                /> */}
                  </div>

                  <h2>Display settings</h2>

                  <div className={style.inputs}>
                    <Checkbox
                      name="showConventional"
                      value={values?.showConventional}
                      isChecked={Boolean(values?.showConventional)}
                      content="Conventional"
                      onChange={handleCheckboxChange}
                    />
                    <Checkbox
                      name="showFHA"
                      value={values?.showFHA}
                      isChecked={Boolean(values?.showFHA)}
                      content="FHA"
                      onChange={handleCheckboxChange}
                    />
                    <Checkbox
                      name="showVA"
                      value={values?.showVA}
                      isChecked={Boolean(values?.showVA)}
                      content="VA"
                      onChange={handleCheckboxChange}
                    />
                    <Checkbox
                      name="showUSDA"
                      value={values?.showUSDA}
                      isChecked={Boolean(values?.showUSDA)}
                      content="USDA"
                      onChange={handleCheckboxChange}
                    />
                    <Checkbox
                      name="showRefinance"
                      value={values?.showRefinance}
                      isChecked={Boolean(values?.showRefinance)}
                      content="Refinance"
                      isDisabled
                      onChange={handleCheckboxChange}
                    />
                    <Checkbox
                      name="showAffordability"
                      value={values?.showAffordability}
                      isChecked={Boolean(values?.showAffordability)}
                      content="Affordability"
                      isDisabled
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <input type="hidden" name="id" value={authUser.uid} />
                  <div>
                    <Button type="submit" data-id={authUser.id} isLoading={isLoading}>
                      Update
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <div className={style.loadingContainer}>
                <Loading />
              </div>
            )}
          </div>
          <div className={cn("col-12", style.widgetCodeWrapper)}>
            <h1>Add widget to your site</h1>
          </div>
          <div className="col-12">
            {/* <div className={style.copyToClipboard}>
              <button className={style.copyButton}> // onClick={this.copyToClipboard}>
                Copied
              </button>
            </div> */}
            <textarea
              readOnly
              style={{
                width: '100%',
                fontSize: '14px',
                padding: '16px',
                height: '160px',
                padding: '12px 20px',
                boxSizing: 'border-box',
                border: '2px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#f8f8f8',
                fontSize: '16px',
                resize: 'none',
                fontFamily: 'monospace',
              }}
              defaultValue={`<script
  async
  type="text/java script"
  src="https://tools.whatsmypayment.com/widgetCode.js"
  data-settings="${authUser.uid}"
  id="whats-my-payment-widget"
></script>`}
            />
            <p>
              To install our mortgage calculator widget, please copy the script tag above and simply
              paste the line of code on your website where you want your calculator to appear.
            </p>
          </div>
        </>
      </div>
    </>
  );
};

export default withFirebase(CalculatorSettings);
