import React from 'react';
import { AuthUserContext } from '../utils/Session';
import { compose } from 'recompose';
import Layout from '../components/Layout';
import { withAuthorization, withEmailVerification } from '../utils/Session';
import CalculatorSettings from '../components/CalculatorSettings';

const condition = (authUser) => !!authUser;
const CalculatorSettingsPage = compose(withEmailVerification, withAuthorization(condition))(CalculatorSettings);

export default () => {
  return (
    <Layout>
      <AuthUserContext.Consumer>
        {(authUser) => <CalculatorSettingsPage authUser={authUser} />}
      </AuthUserContext.Consumer>
    </Layout>
  );
};
